import { alertController } from '@ionic/vue';
import { Sentry, isSentryInitialized } from '@/plugins/sentry';
import {
  CompanyEnum,
  EnvVariablesEnum,
  PagesBackgroundEnum,
  PostsModeEnum,
  RequirementsEnum,
  UserRoleEnum,
} from '@/enums';
import {
  isNativeMobile,
  useTheme,
  HomePageHelper,
  openNetworkChooseModal,
  updateApp,
  changeFavicon,
  ColorGenerator,
  useRequirements,
  useAiAssistant,
  useImages,
  useNotifications,
  isPushInitialized,
  useEnvs,
} from '@/helpers';
import { loadLocaleMessages } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import type { AppState } from '@/store';
import {
  useAuthStore,
  useAppStore,
  useMessengerStore,
  useNetworkStore,
  useGroupsStore,
  useUserStore,
  useNotificationsStore,
  usePostStore,
  useMenuStore,
  resetAllStores,
  useAiAssistantStore,
  useBadgesStore,
  useDocStore,
} from '@/store';
import type { AuthFormModel, NetworkModel, UserCurrentModel } from '@/types';
import { markEnd, markStart } from '@/helpers/performance';
import { useRedirect } from '@/helpers/useRedirectHelper';
import { isCustomDomain } from '@/services/checkers';
import { TokenModeEnum } from '@/enums/auth';
import { abortAllRequests } from '@/services/axios';
import { useWebSockets } from '@/ws';

type SetupAppPayload = {
  /**
   * Used to initialize WebSockets connection
   * @default true
   */
  newWebSocket?: boolean;
  /**
   * Used to initialize Push Notifications
   * @default true
   */
  newPush?: boolean;
  /**
   * Used to skip requirements check
   * @default false
   */
  skipRequirementsCheck?: boolean;
  /**
   * Used to skip redirect
   * @default false
   */
  skipRedirect?: boolean;
};

type IUseSession = {
  /**
   * The current user of the app
   *
   * @see src/plugins/sentry.ts
   * @readonly
   */
  localUser: UserCurrentModel | null;
  /**
   * General setup function for the app
   *
   * @summary Setup network, user, profile settings, locales, check required conditions, to app + init WebSockets and/or Push Notifications.
   * Accepts newWebSocket and newPush as optional parameters for _checkRequiredConditions => _toApp.

   * @param newWebSocket boolean
   * @description If newWebSocket is false, then it will NOT re-initialize WebSockets connection.
   * @default true
   * @see src/helpers/useWebSockets.ts

   * @param newPush boolean
   * @description If newPush is false, then it will NOT re-initialize Push Notifications. It will hoverer if isPushInitialized is false.
   * @default true
   * @see src/helpers/useNotificationsHelper.ts
   *
   * @param cold boolean
   * @description If cold is true, then it will NOT perform requirements check.
   * @default false
   */
  setupApp: (payload: SetupAppPayload) => Promise<void>;
  /**
   * Sets the network for the user
   *
   * @param network
   * @param data
   * @returns
   */
  setNetwork: (network: NetworkModel, isNetworkSwitch: boolean, userData?: AuthFormModel) => Promise<void>;
  /**
   * Changes the network for the user.
   * Can handle both single NetworkModel and NetworkModel[]:
   * - If single NetworkModel - `setNetwork()` is called with it
   * - If NetworkModel[] - modal is opened to select a network and `setNetwork()` is called with it
   */
  changeNetwork: (userNetworks: NetworkModel[], isNetworkSwitch: boolean, data?: AuthFormModel) => Promise<void>;
  /**
   * Logs out the user from the application:
   *  - Stops WebSockets
   *  - resets badge count
   *  - deletes ai assistant
   *  - cancels push notifications
   *  - clears custom menu timer
   *  - resets all stores
   *  - changes favicon
   *  - clears theme
   *  - clears images cache
   */
  logOut: () => Promise<void>;
  /**
   * Sets the custom menu timer
   *
   * @deprecated
   * @todo Refactor
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2052
   */
  setCustomMenuTimer: () => void;
  /**
   * Clears the custom menu timer
   *
   * @deprecated
   * @todo Refactor
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2052
   */
  clearCustomMenuTimer: () => void;

  /** Use it for check Registration is enabled
   *
   * @readonly
   */
  readonly registrationEnabled: boolean;
};

export function useSession(): IUseSession {
  //#region Public variables
  let localUser: UserCurrentModel | null = null;
  const registrationEnabled = import.meta.env.VITE_ENABLE_REGISTRATION === 'true';
  //#endregion

  //#region Private methods
  async function _setupNetwork(): Promise<boolean> {
    if (!useNetworkStore().network.id) {
      await useNetworkStore().networkForUser();
    }

    if (useNetworkStore().data.length <= 1) {
      await useNetworkStore().networksByUser();
    }

    const settings = await useNetworkStore().getSettings();

    if (!settings) {
      console.warn('[WARN] Failed to get settings');
      return false;
    }

    const color = useTheme().getAppColor(settings?.headBackgroundColor);
    await useTheme().setTheme(color);
    changeFavicon();
    return true;
  }

  async function _setupUser(): Promise<UserCurrentModel | null> {
    localUser = await useUserStore().currentUser();

    if (!localUser) {
      return null;
    }

    useAuthStore().setUserId(localUser.id);

    return localUser;
  }

  async function _handleSetupAppError(): Promise<void> {
    useAppStore().setIsLoading(false);
    useAppStore().setIsNetworkChanged(false);

    await router?.replace(ROUTES_NAME.LOGIN);
    const networkErrors = useNetworkStore().getErrors('default');
    const userErrors = useUserStore().getErrors('default');
    const errors = userErrors.length && !networkErrors.length ? userErrors : networkErrors;

    isSentryInitialized() && Sentry?.setUser(null);

    const alert = await alertController.create({
      message: errors.join(' '),
    });
    await alert.present();
  }

  function _setupProfileSettings(localUser: UserCurrentModel): void {
    useAppStore().$patch((state: AppState) => {
      state.isLoading = true;

      state.theme = Object.hasOwn(localUser, 'themeStyle') ? localUser.themeStyle : state.theme;

      state.pagesBackground = Object.hasOwn(localUser, 'pagesBackground')
        ? localUser.pagesBackground
          ? PagesBackgroundEnum.CustomImage
          : PagesBackgroundEnum.Default
        : state.pagesBackground;

      state.locale = Object.hasOwn(localUser, 'language') ? localUser.language : state.locale;

      state.sendKey = Object.hasOwn(localUser, 'sendMode') ? localUser.sendMode : state.sendKey;

      state.localNotifications = Object.hasOwn(localUser, 'sound') ? localUser.sound : state.localNotifications;

      state.canPostOnBehalfMe = Object.hasOwn(localUser, 'canPostOnBehalfMe')
        ? localUser.canPostOnBehalfMe
        : state.canPostOnBehalfMe;
    });

    useAppStore().setTheme(useAppStore().theme);
  }

  function _setupDocsInNewWindow(): void {
    try {
      const authStore = useAuthStore();
      const appStore = useAppStore();

      if (!authStore || !authStore.companyRowId) {
        console.debug('[DEBUG] Auth store not ready yet, skipping docs-in-new-window check');
        return;
      }

      const companiesWithDocsInNewWindow = useEnvs().getEnvValue(EnvVariablesEnum.CompaniesListWithDocsInNewWindow);
      const currentCompanyId = authStore.companyRowId;

      const enable =
        companiesWithDocsInNewWindow[CompanyEnum.SHGT] === currentCompanyId ||
        companiesWithDocsInNewWindow[CompanyEnum.AWO] === currentCompanyId;

      appStore.setDocsInNewWindow(enable);
    } catch (e) {
      console.warn('[WARN] Failed to check company-specific docs-in-new-window setting', e);
    }
  }

  async function _setupLocales(): Promise<void> {
    const companyResources = await useAppStore().getCompanyResources();
    const currentNetworkId = useNetworkStore().getNetwork.id;
    await loadLocaleMessages(useAppStore().locale, companyResources || {}, currentNetworkId);
  }

  async function _handleTargetLocation(): Promise<void> {
    const route = router.currentRoute.value;

    const targetUrl = route.query.redirect ? String(route.query.redirect) : null;
    if (targetUrl) {
      await router.push(targetUrl);
      return;
    }

    await HomePageHelper.setHomePage();

    if (route?.name === ROUTES_NAME.LOGIN || route?.name === ROUTES_NAME.HOME) {
      await router?.replace(useAppStore().homePage);
      return;
    }

    if (HomePageHelper.getHomePage()?.name === ROUTES_NAME.FEED) {
      await router?.replace({ name: ROUTES_NAME.FEED });
      usePostStore().setPostsMode(PostsModeEnum.Feed);
      usePostStore().setPostsGroupId(null);
      usePostStore().setPostsUserId(null);
      return;
    }

    await router?.replace(useAppStore().homePage);
  }

  async function _toApp(newWebSocket = true, newPush = true, skipRedirect = false): Promise<void> {
    if (!localUser) {
      await router?.push(ROUTES_NAME.LOGIN);
      return;
    }

    if (newWebSocket) {
      await useWebSockets().initWebSockets(useAuthStore().getWebSocketModel);
    }

    if (newPush || !isPushInitialized()) {
      await useNotifications().initPushNotifications();
    }

    useUserStore().$reset();
    useUserStore().setCurrentUser(localUser);
    useAppStore().setFeedType(localUser.defaultFeedType);

    /**
     * @note On mobile init, caused by local/push notification handling
     * we need to ignore following routing logic
     * since final route is now dictated by push notification type/id
     */
    if (!useAppStore().fromMobilePush && !skipRedirect) await _handleTargetLocation();

    if (isNativeMobile) await updateApp();

    await useMessengerStore().getChains();
    for (let i = 0; i < 10 && useMessengerStore().hasLoadMoreUrl; i++) {
      const url = useMessengerStore().hasLoadMoreUrl;
      if (url) await useMessengerStore().loadMore(url);
    }
  }

  async function _checkRequiredConditions(): Promise<void> {
    const result = await useRequirements().check(RequirementsEnum.All);
    if (result) {
      useAppStore().setIsLoading(false);
    }
  }

  /** #2307 Disable filter in docs browser for some companies */
  function _setupUsersFilterInDocsBrowser(): void {
    try {
      const authStore = useAuthStore();
      const userStore = useUserStore();
      const docStore = useDocStore();

      if (!authStore || !authStore.companyRowId) {
        console.debug('[DEBUG] Auth store not ready yet, skipping disabled-filter-in-docs-browserw check');
        return;
      }

      const CompaniesListWithDisabledUsersFilterInDocsBrowser = useEnvs().getEnvValue(
        EnvVariablesEnum.CompaniesListWithDisabledUsersFilterInDocsBrowser
      );
      const currentCompanyId = authStore.companyRowId;
      const currentUserRoleId = userStore.current?.roleId ?? 0;

      const disabled =
        CompaniesListWithDisabledUsersFilterInDocsBrowser[CompanyEnum.BKG] === currentCompanyId &&
        currentUserRoleId < UserRoleEnum.Moderator;

      docStore.setUsersFilterEnabled(!disabled);
    } catch (e) {
      console.warn('[WARN] Failed to check company-specific disabled-filter-in-docs-browser setting', e);
    }
  }
  //#endregion

  //#region Public methods
  async function setupApp(payload: SetupAppPayload): Promise<void> {
    const { newWebSocket = true, newPush = true, skipRequirementsCheck = false, skipRedirect = false } = payload;
    markStart('setupApp');
    clearCustomMenuTimer();

    resetAllStores(false);

    ColorGenerator.generateRandomShades();

    try {
      const _result = await _setupNetwork();
      if (!_result) throw new Error('Network error');

      localUser = await _setupUser();
      if (!localUser) throw new Error('Current user error');
    } catch (e) {
      console.error('[ERROR] Failed to setupApp', e);
      await _handleSetupAppError();
      return;
    }

    if (isSentryInitialized() && Sentry) {
      Sentry.setUser({
        email: localUser?.email,
        id: localUser?.id.toString(),
        username: localUser?.fullName,
      });
    }

    _setupProfileSettings(localUser);

    _setupDocsInNewWindow();

    _setupUsersFilterInDocsBrowser();
    try {
      await Promise.allSettled([
        _setupLocales(),
        !skipRequirementsCheck && _checkRequiredConditions(),
        _toApp(newWebSocket, newPush, skipRedirect),
        useNotificationsStore().notifications(),
        useGroupsStore().canPostGroups(),
        useMenuStore().getMenu(),
        useGroupsStore().myGroups(),
        !useBadgesStore().data.length && useBadgesStore().badgesAll(),
      ]);
    } catch (e: any) {
      console.error('[ERROR] Error during setupApp', e);
    }

    setCustomMenuTimer();
    useAppStore().setIsNetworkChanged(false);
    markEnd('setupApp');
  }

  async function setNetwork(network: NetworkModel, isNetworkSwitch: boolean, userData?: AuthFormModel): Promise<void> {
    console.debug('[DEBUG] network', network, 'isNetworkSwitch', isNetworkSwitch, 'userData', userData);
    const { shouldRedirect, redirectToCustomDomain } = useRedirect();
    const authType = useAuthStore().authType ?? 'Form';
    console.debug('[DEBUG] authType', authType);

    if (!authType) {
      console.error('[ERROR] Auth type must be defined for network operations');
      return;
    }

    /** @see docs/AUTH_MATRIX.md */
    const isRedirect = shouldRedirect({
      currentHost: window.location.hostname,
      targetHost: network.host,
      domainType: isCustomDomain() ? 'Custom' : 'Generic',
      authType,
      isNetworkSwitch,
    });

    if (isRedirect) {
      redirectToCustomDomain(network);
      return;
    }

    const targetHost = authType === 'Form' ? network.host : network.hostWithSubHost;
    const normalizedHost = targetHost.replace(/^https?:\/\//, '');
    // .replace('core-beta2', 'core');
    useAuthStore().setHost(normalizedHost);

    useNetworkStore().setSettingNetwork(true);
    useAuthStore().setNetwork(network.id, network.webSocket, true);

    if (userData && !useAuthStore().isAuth()) {
      await useAuthStore().homeCode(userData);
    }

    await useAuthStore().token(TokenModeEnum.Code, { force: true });

    if (!useAuthStore().isAuth()) {
      console.warn('[WARN] User is not authenticated');
      await logOut();
      return;
    }

    useNetworkStore().setNetwork(network);
    useNetworkStore().setSettingNetwork(false);

    // Setup app
    useAppStore().setIsLoading(true);
    await setupApp({ newWebSocket: true, newPush: true });
    useAppStore().setIsLoading(false);
  }

  async function changeNetwork(
    userNetworks: NetworkModel[],
    isNetworkSwitch: boolean,
    data?: AuthFormModel
  ): Promise<void> {
    if (!userNetworks.length) {
      console.warn('[WARN] No network found');
      return;
    }

    if (userNetworks.length === 1) {
      await setNetwork(userNetworks[0], isNetworkSwitch, data);
      return;
    }

    const result = await openNetworkChooseModal(false);
    if (!result) {
      console.warn('[WARN] No network selected');
      return;
    }

    await setNetwork(result, isNetworkSwitch, data);
  }

  async function logOut(): Promise<void> {
    try {
      abortAllRequests();

      await useWebSockets().stopWebSockets();

      await useNotifications().resetBadgeCount();

      const currentAssistant = useAiAssistantStore().assistant;
      if (currentAssistant) {
        await useAiAssistant().deleteAssistant(currentAssistant);
      }

      if (isNativeMobile && useAuthStore().isAuth()) {
        await useNotifications().cancelPushNotifications();
      }

      clearCustomMenuTimer();

      resetAllStores(true);

      changeFavicon();

      await useTheme().clearTheme();

      useImages().clearImages();
    } catch (e) {
      console.error(e);
    }
  }

  function setCustomMenuTimer() {
    const customMenuTimerId = setInterval(
      async () => {
        await useMenuStore().getMenu();
      },
      15 * 60 * 1000
    );
    useMenuStore().setTimer(customMenuTimerId);
  }

  function clearCustomMenuTimer() {
    const customMenuTimerId = useMenuStore().timer;
    if (customMenuTimerId) {
      clearInterval(customMenuTimerId);
    }
    useMenuStore().setTimer(null);
  }
  //#endregion

  return {
    get localUser(): UserCurrentModel | null {
      return localUser;
    },
    setupApp,
    setNetwork,
    changeNetwork,
    logOut,
    clearCustomMenuTimer,
    setCustomMenuTimer,
    registrationEnabled,
  };
}
