/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import type { App } from 'vue';
import packageJson from '../../package.json';
import { useErrors, useSession } from '@/helpers';
import { useUserStore } from '@/store';

const sentryInitialized = false;

/**
 * @note Temporary decision to disable Sentry
 * due to quota overflow
 * @todo Implement a better Sentry integration
 * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2158
 */
export const initSentry = (app: App<Element>): void => {
  console.warn('[WARN] Sentry is disabled due to quota overflow');
  return;

  // try {
  //   console.log('[INFO] Attempting to initialize Sentry...');

  //   if (window.location.hostname === 'localhost') {
  //     console.warn('Sentry is disabled in local environment');
  //     return;
  //   }

  //   const id = useUserStore()?.current?.id;
  //   const currentUser = {
  //     name: (!id ? useSession()?.localUser?.fullName : useUserStore().getUserProfile(id).fullName) ?? 'Anonymous',
  //     email: (!id ? useSession()?.localUser?.email : useUserStore().getUserProfile(id).email) ?? 'Anonymous',
  //   };

  //   //#region Sentry Integrations
  //   /**
  //    * @note Feedback integration is temporarily disabled
  //    * @see https://docs.sentry.io/platforms/javascript/guides/vue/user-feedback/configuration
  //    */
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   const feedback = SentryVue.feedbackIntegration({
  //     autoInject: false,
  //     showBranding: false,
  //     colorScheme: 'system',
  //     isNameRequired: true,
  //     isEmailRequired: true,
  //     showName: true,
  //     showEmail: true,
  //     useSentryUser: currentUser,
  //     buttonLabel: 'Report a Bug',
  //     formTitle: 'Report a Bug',
  //     submitButtonLabel: 'Send Bug Report',
  //     cancelButtonLabel: 'Cancel',
  //     nameLabel: 'Name',
  //     namePlaceholder: 'Your Name',
  //     emailLabel: 'Email',
  //     isRequiredLabel: '(required)',
  //     messageLabel: 'Description',
  //     messagePlaceholder: `What's the bug? What did you expect?`,
  //     successMessageText: 'Thank you for your report!',
  //     onSubmitSuccess: () => {
  //       useErrors().handleSuccessfulSubmit();
  //     },
  //     onSubmitError: async () => {
  //       console.error('Feedback form error');
  //     },
  //   });

  //   /**
  //    * @note Replay integration is temporarily disabled
  //    * @see https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/configuration
  //    */
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   const replay = SentryVue.replayIntegration({
  //     stickySession: true,
  //     maskAllText: __DEV__ ? false : true,
  //     maskAllInputs: __DEV__ ? false : true,
  //     blockAllMedia: __DEV__ ? false : true,
  //     networkDetailAllowUrls: [/.*/],
  //     networkRequestHeaders: [
  //       'Accept-Language',
  //       'Authorization',
  //       'BrowserPlatforms',
  //       'CapacitorPlatform',
  //       'IsNativePlatform',
  //       'ApiVersion',
  //     ],
  //     networkResponseHeaders: [],
  //   });

  //   /**
  //    * @see https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/browsertracing
  //    */
  //   const browserTracing = SentryVue.browserTracingIntegration({
  //     idleTimeout: 1000,
  //   });
  //   //#endregion

  //   Sentry.init(
  //     {
  //       app,
  //       dsn: import.meta.env.VITE_SENTRY_DSN,
  //       release: `intra-ionic@${packageJson.version}${import.meta.env.VITE_APP_STAGE}`,
  //       dist: `${packageJson.build}`,
  //       environment: __DEV__ ? 'dev' : 'production',
  //       logErrors: true,
  //       integrations: [browserTracing],
  //       /**
  //        * @note Feedback and Replay integrations are temporarily disabled
  //        */
  //       // integrations: [feedback, replay, browserTracing],
  //       // replaysSessionSampleRate: 0,
  //       // replaysOnErrorSampleRate: __DEV__ ? 0 : 0.5,
  //       // replaysOnErrorSampleRate: 0,
  //       trackComponents: true,
  //       tracesSampleRate: 1.0,
  //       beforeSend(event) {
  //         useErrors().setSentryEvent(event);
  //         return event;
  //       },
  //     },
  //     SentryVue.init
  //   );

  //   sentryInitialized = true;
  //   console.log('[INFO] Sentry initialized successfully');
  // } catch (e) {
  //   console.error('[ERROR] Failed to initialize Sentry:', e);
  //   console.warn('[WARN] Continuing without Sentry...');
  //   sentryInitialized = false;
  // }
};

export const isSentryInitialized = (): boolean => sentryInitialized;

export { Sentry, SentryVue };
